<template>

<b-container class="vertical-space">

  <h1>{{ t('register') }}</h1>

  <b-form @submit="submit">

    <b-form-group id="input-group-username" :label="t('username')" label-for="input-username">
      <b-form-input id="input-username" v-model="form.username" tabindex="1" required></b-form-input>
    </b-form-group>

    <b-form-group id="input-group-email" :label="t('email')" label-for="input-email">
      <b-form-input id="input-email" tabindex="2" type="email" v-model="form.email" required></b-form-input>
    </b-form-group>


    <b-form-group id="input-group-language" :label="t('language')" label-for="input-language">
      <b-form-select tabindex="3" v-model="form.lang" :options="languages"></b-form-select>
    </b-form-group>

    <b-button type="submit" tabindex="4" variant="success" class="mt-4" >{{ t('signin') }}</b-button>

  </b-form>

</b-container>

</template>

<script>

export default {
  data () {
    return {
      form: {
        username: 'jza',
        email: 'jza34@sldja.com',
        lang: 'fr',
      },
      languages: [
        { value: 'fr', text: 'Français' },
      ]
    }
  },
  methods: {
    t: function(s) {
      return this.$store.getters.t(s)
    },
    submit: function() {
      this.$http.post('/register', this.form)
      .then(()=> {
        window.open("/", '_self')
      })
      .catch(error => {
        this.$store.dispatch('handleError', error.response)
      })

      return false;
    }
  }
}
</script>

<style scoped lang="scss">

</style>
